
import { defineComponent } from 'vue';

const BaseNotificationBanner = defineComponent({
  emits: ['hide'],

  props: {
    title: {
      type: String,
      required: true,
    },
  },
});

export default BaseNotificationBanner;
