
import {
  defineComponent,
  ref,
  computed,
  inject,
} from 'vue';
import { Form } from 'vee-validate';
import BaseCard from '@/components/base/BaseCard';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import useFaunaDB from '@/mixins/useFaunaDB';
import RequestStatus from '@/constants/RequestStatus';
import BaseNotificationBanner from '@/components/base/BaseNotificationBanner.vue';
import AuthState from '@/types/AuthState';
import * as Sentry from '@sentry/vue';

const UserDetails = defineComponent({
  components: {
    BaseCard,
    BaseInput,
    BaseButton,
    Form,
    BaseNotificationBanner,
  },

  setup() {
    const { updateUserRecord } = useFaunaDB();
    const { authState, update } = inject('AuthModule') as {
      authState: AuthState;
      update: Function;
    };
    const validationSchema = {
      name: 'required',
      email: 'required|email',
    };

    const status = ref<RequestStatus>(RequestStatus.Initial);
    const plaintext = ref<boolean>(true);
    const user = JSON.parse(localStorage.getItem('user') || 'null');
    const name = ref<string>(user.name || '');
    const email = ref<string>(user.email);
    const notificationBannerVisible = ref<boolean>(false);

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const onCancel = () => {
      plaintext.value = true;
      email.value = user.email;
      name.value = user.name;
    };

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;
      const isNewEmail = user.email !== email.value;

      update({
        ...authState.user,
        ...{ email: email.value, name: name.value },
      });

      const response = await updateUserRecord();

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to update user record from user details section'));

        return;
      }

      status.value = RequestStatus.Success;
      user.name = name.value;
      user.email = email.value;
      plaintext.value = true;
      if (isNewEmail) notificationBannerVisible.value = true;
    };

    return {
      name,
      email,
      plaintext,
      onSubmit,
      validationSchema,
      isLoading,
      isError,
      notificationBannerVisible,
      onCancel,
    };
  },
});

export default UserDetails;
