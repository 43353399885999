import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-auto w-11/12 md:w-4/6 pb-20" }
const _hoisted_2 = { class: "text-center mt-20 mb-14" }
const _hoisted_3 = { class: "text-24 md:text-30 lg:text-36 mb-5 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDetails = _resolveComponent("UserDetails")
  const _component_UserPassword = _resolveComponent("UserPassword")
  const _component_UserLanguage = _resolveComponent("UserLanguage")
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('user-account.title')), 1)
        ]),
        _createVNode(_component_UserDetails, { class: "mb-4" }),
        _createVNode(_component_UserPassword, { class: "mb-4" }),
        _createVNode(_component_UserLanguage)
      ])
    ]),
    _: 1
  }))
}