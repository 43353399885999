import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2" }
const _hoisted_2 = { class: "col-span-2 sm:col-span-1 px-4 sm:px-0 sm:pl-8" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3 text-input-danger"
}
const _hoisted_4 = { class: "col-span-2 sm:col-span-1 mt-2 sm:mt-0 px-4 flex justify-end items-center" }
const _hoisted_5 = {
  key: 0,
  class: "col-span-2 text-right mt-10 pt-3 border-t px-4 border-card-action px-"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePasswordInput = _resolveComponent("BasePasswordInput")
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_Form = _resolveComponent("Form")
  const _component_BaseCard = _resolveComponent("BaseCard")

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "pt-4 pb-3" }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        onSubmit: _ctx.onSubmit,
        "validation-schema": _ctx.validationSchema
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              (_ctx.isError && !_ctx.plaintext)
                ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('errors.system_error')), 1))
                : _createCommentVNode("", true),
              (_ctx.plaintext)
                ? (_openBlock(), _createBlock(_component_BasePasswordInput, {
                    key: 1,
                    modelValue: _ctx.newPassword,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.newPassword = $event)),
                    name: "currentPassword",
                    label: _ctx.$t('user-account.change-password.password.label'),
                    placeholder: _ctx.$t('user-account.change-password.password.placeholder'),
                    plaintext: _ctx.plaintext,
                    togglable: ""
                  }, null, 8, ["modelValue", "label", "placeholder", "plaintext"]))
                : _createCommentVNode("", true),
              (!_ctx.plaintext)
                ? (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
                    _createVNode(_component_BasePasswordInput, {
                      modelValue: _ctx.newPassword,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.newPassword = $event)),
                      name: "newPassword",
                      "input-classes": "w-full md:w-80",
                      class: "mt-5",
                      label: _ctx.$t('user-account.change-password.new-password.label'),
                      placeholder: _ctx.$t('user-account.change-password.new-password.placeholder'),
                      togglable: ""
                    }, null, 8, ["modelValue", "label", "placeholder"]),
                    _createVNode(_component_BasePasswordInput, {
                      modelValue: _ctx.confirmPassword,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmPassword = $event)),
                      name: "confirmPassword",
                      "input-classes": "w-full md:w-80",
                      class: "mt-5",
                      label: _ctx.$t('user-account.change-password.confirm-password.label'),
                      placeholder: _ctx.$t('user-account.change-password.confirm-password.placeholder'),
                      togglable: ""
                    }, null, 8, ["modelValue", "label", "placeholder"])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _createVNode("div", _hoisted_4, [
              (_ctx.plaintext)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    size: "sm",
                    icon: ['fas', 'pen'],
                    outline: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.plaintext = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.change')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.plaintext)
              ? (_openBlock(), _createBlock("div", _hoisted_5, [
                  _createVNode(_component_BaseButton, {
                    outline: "",
                    onClick: _ctx.onCancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_BaseButton, {
                    class: "ml-3",
                    type: "submit",
                    loading: _ctx.isLoading,
                    variant: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.save')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"])
    ]),
    _: 1
  }))
}