
import { computed, defineComponent, ref } from 'vue';
import { Form } from 'vee-validate';
import BaseCard from '@/components/base/BaseCard';
import BaseButton from '@/components/base/BaseButton.vue';
import BasePasswordInput from '@/components/base/BasePasswordInput.vue';
import RequestStatus from '@/constants/RequestStatus';
import useAuth from '@/mixins/useAuth';
import * as Sentry from '@sentry/vue';

const UserPassword = defineComponent({
  components: {
    BaseCard, BasePasswordInput, BaseButton, Form,
  },

  setup() {
    const { changeUserPassword } = useAuth();
    const validationSchema = {
      newPassword: 'required',
      confirmPassword: 'required|confirmed:@newPassword',
    };

    const status = ref<RequestStatus>(RequestStatus.Initial);
    const plaintext = ref<boolean>(true);
    const newPassword = ref<string>('');
    const confirmPassword = ref<string>('');

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const onCancel = (): void => {
      newPassword.value = '';
      confirmPassword.value = '';
      plaintext.value = true;
    };

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;

      const response = await changeUserPassword(newPassword.value);

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Could not change user password'));

        return;
      }

      status.value = RequestStatus.Success;
      onCancel();
    };

    return {
      newPassword,
      confirmPassword,
      plaintext,
      onSubmit,
      onCancel,
      isLoading,
      isError,
      validationSchema,
    };
  },
});

export default UserPassword;
