
import { defineComponent } from 'vue';

import DefaultLayout from '@/components/layout/DefaultLayout.vue';
import UserDetails from '@/components/UserAccount/UserDetails.vue';
import UserPassword from '@/components/UserAccount/UserPassword.vue';
import UserLanguage from '@/components/UserAccount/UserLanguage.vue';

const TheUserAccount = defineComponent({
  components: {
    DefaultLayout, UserDetails, UserPassword, UserLanguage,
  },
});

export default TheUserAccount;
