import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2" }
const _hoisted_2 = { class: "col-span-2 sm:col-span-1 px-4 sm:px-0 sm:pl-8" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3 text-input-danger"
}
const _hoisted_4 = { class: "col-span-2 sm:col-span-1 text-right px-4" }
const _hoisted_5 = {
  key: 0,
  class: "col-span-2 text-right mt-10 pt-3 border-t px-4 border-card-action px-"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_Form = _resolveComponent("Form")
  const _component_BaseNotificationBanner = _resolveComponent("BaseNotificationBanner")
  const _component_BaseCard = _resolveComponent("BaseCard")

  return (_openBlock(), _createBlock(_component_BaseCard, { class: "pt-4 pb-3" }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        onSubmit: _ctx.onSubmit,
        "validation-schema": _ctx.validationSchema
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              (_ctx.isError && !_ctx.plaintext)
                ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('errors.system_and_email')), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_BaseInput, {
                modelValue: _ctx.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.name = $event)),
                class: "mb-6",
                "input-classes": "w-full md:w-80",
                name: "name",
                label: _ctx.$t('user-account.details.name.label'),
                placeholder: _ctx.$t('user-account.details.name.placeholder'),
                plaintext: _ctx.plaintext,
                clearable: ""
              }, null, 8, ["modelValue", "label", "placeholder", "plaintext"]),
              _createVNode(_component_BaseInput, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.email = $event)),
                name: "email",
                "input-classes": "w-full md:w-80",
                label: _ctx.$t('user-account.details.email.label'),
                placeholder: _ctx.$t('user-account.details.email.placeholder'),
                plaintext: _ctx.plaintext,
                clearable: ""
              }, null, 8, ["modelValue", "label", "placeholder", "plaintext"])
            ]),
            _createVNode("div", _hoisted_4, [
              (_ctx.plaintext)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    size: "sm",
                    icon: ['fas', 'pen'],
                    outline: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.plaintext = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.edit')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.plaintext)
              ? (_openBlock(), _createBlock("div", _hoisted_5, [
                  _createVNode(_component_BaseButton, {
                    outline: "",
                    onClick: _ctx.onCancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_BaseButton, {
                    class: "ml-3",
                    type: "submit",
                    loading: _ctx.isLoading,
                    variant: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('actions.save')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"]),
      (_ctx.notificationBannerVisible)
        ? (_openBlock(), _createBlock(_component_BaseNotificationBanner, {
            key: 0,
            title: _ctx.$t('email-confirmation.title'),
            onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.notificationBannerVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('email-confirmation.message')), 1)
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}