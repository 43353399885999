
import {
  defineComponent,
  ref,
  computed,
  inject,
} from 'vue';
import BaseCard from '@/components/base/BaseCard';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import languages from '@/data/static/languages.json';
import SelectOption from '@/types/SelectOption';
import UserAccountConfigurations from '@/constants/UserAccountConfigurations';
import RequestStatus from '@/constants/RequestStatus';
import useFaunaDB from '@/mixins/useFaunaDB';
import AuthState from '@/types/AuthState';
import SupportedLanugages from '@/constants/SupportedLanugages';
import * as Sentry from '@sentry/vue';

const UserLanguage = defineComponent({
  components: { BaseCard, BaseSelect, BaseButton },

  setup() {
    const { updateUserRecord } = useFaunaDB();
    const { authState, update } = inject('AuthModule') as { authState: AuthState; update: Function };

    const status = ref<RequestStatus>(RequestStatus.Initial);
    const plaintext = ref<boolean>(true);
    const selectedLanguage = ref<string>(
      authState.user?.searchSettings.language || UserAccountConfigurations.DefaultLanguage,
    );

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);

    const languageOptions: SelectOption[] = Object.values(languages)
      .filter(
        (lang: { name: string; code: string }) => Object.values(SupportedLanugages).flat().includes(lang.code),
      )
      .map(
        (lang: { name: string; code: string }) => ({
          value: lang.code,
          text: lang.name,
        }),
      );

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;

      update({
        ...authState.user,
        searchSettings: {
          databases: authState.user.searchSettings.databases,
          language: selectedLanguage.value,
          downloadSize: authState.user.searchSettings.downloadSize,
        },
      });

      const response = await updateUserRecord();

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Failed to update user record from user language section'));

        return;
      }

      status.value = RequestStatus.Success;
      plaintext.value = true;
    };

    return {
      selectedLanguage,
      plaintext,
      onSubmit,
      languageOptions,
      isLoading,
      isError,
    };
  },
});

export default UserLanguage;
